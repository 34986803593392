import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import BoxList from "src/components/BoxList"
import { PrimaryTitle } from "src/components/Typography"

const subpages = [
  {
    title: "Mission & values",
    subtitle: "Read about the mission and values which are key to our approach",
    path: "/about/mission-and-values",
    icon: "feather-compass",
  },
  {
    title: "History",
    subtitle: "Go through our timeline and discover AI’s history",
    path: "/about/history",
    icon: "feather-book-open",
  },
  {
    title: "Team",
    subtitle: "Meet the team",
    path: "/about/team",
    icon: "feather-users",
  },
  {
    title: "Organisations",
    subtitle: "Learn about our work across six countries",
    path: "/about/organisations",
    icon: "feather-globe",
  },
  {
    title: "How we work",
    subtitle: "Learn about our strategies",
    path: "/about/how-we-work",
    icon: "feather-info",
  },
]

const AboutPage = () => {
  const [observed, setObserved] = useState(false)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo
        title="Find out more about Anima International - Animal advocacy"
        description="Who are we? Who makes up the Anima International team? How was AI created and what is our goal? Read and find out more about Anima International."
      />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">About us</PrimaryTitle>
      </PageHeader>
      <BaseContainer>
        <BoxList items={subpages} />
      </BaseContainer>
    </>
  )
}

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

export default AboutPage
